import { getFirstOfArray } from '/machinery/getFirstOfArray'
import { ensureFloat } from '/machinery/ensureValue'

export function mapJobData({ job, lang }) {
  const {
    job_id: id,
    job_title: title,
    city,
    language,
    country: countries,
    recruiter_name: recruiterName,
    recruiter_email: recruiterEmail,
    job_description: description,
    contract_type: contractTypes,
    remote_type: remoteTypes,
    job_branch: jobBranches,
    salary_currency: salaryCurrency,
    salary_max: salaryMax,
    salary_min: salaryMin,
    hours_max: hoursMax,
    date_start: dateStart
  } = job

  const country = getFirstOfArray(countries)

  return {
    id,
    title,
    city,
    country,
    countryName: country?.[`label_${lang}`],
    countryCode: country?.code,
    recruiterName,
    recruiterEmail,
    description,
    language,
    contractType: (contractTypes) ? getFirstOfArray(contractTypes)?.[`label_${lang}`] : null,
    remoteType: (remoteTypes) ? getFirstOfArray(remoteTypes)?.[`label_${lang}`] : null,
    jobBranches,
    salaryCurrency,
    salaryMax,
    salaryMin,
    hoursMax: ensureFloat(hoursMax, 0),
    dateStart
  }
}
