import { routeMap } from '/routeMap'
import { getSalaryRange } from '/machinery/getSalaryRange'
import { mapJobData } from '/machinery/mapJobData'
import { useLanguage, useTranslate } from '/machinery/I18n'
import { makeSlug } from '/machinery/makeSlug'

import { HeadingMd } from '/features/buildingBlocks/Heading'
import { ButtonLinkPrimary } from '/features/buildingBlocks/Button'
import { JobCard } from '/features/buildingBlocks/JobCard'

import styles from './Jobs.css'

export function Jobs({ title, jobs, description, button }) {
  const { __ } = useTranslate()
  const language = useLanguage()

  return (
    <div className={styles.component}>
      <div className={styles.content}>
        <HeadingMd h={3} {...{ title }} />
        {description && <p className={styles.text}>{description}</p>}
      </div>

      <div className={styles.containerJobs}>
        <ul className={styles.jobs}>
          {jobs?.slice(0, 5).map(({ _source: job }) => {
            const {
              id: jobId,
              title: title,
              country: jobCountry,
              city,
              contractType,
              remoteType,
              salaryCurrency,
              salaryMax,
              salaryMin,
              hoursMax
            } = mapJobData({ job, lang: language })

            const labels = [
              ...(remoteType) ? [{ title: remoteType }] : [],
              { title: jobCountry?.code ? `${city} (${jobCountry.code.toUpperCase()})` : city },
              ...(salaryMax && salaryMin) ? [{ title: getSalaryRange({ locale: language, salaryMin, salaryMax, salaryCurrency }) }] : [],
              ...(hoursMax > 0) ? [{ title: __({ hours: hoursMax })`job-hours-max` }] : [],
              ...(contractType) ? [{ title: contractType }] : [],
            ]

            return (
              <li key={jobId}>
                <JobCard
                  smallTitle
                  {...{ labels, title }}
                  href={routeMap.app.job({
                    language,
                    jobId,
                    jobTitle: makeSlug({ input: job.job_title, language: job.job_language })
                  })}
                />
              </li>
            )
          })}
        </ul>
        {button?.href && <ButtonLinkPrimary
          href={button.href}
          label={button.label}
          dataX='goto-jobs-overview'
        />}
      </div>
    </div>
  )
}
