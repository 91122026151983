import { useMediaQuery } from '@kaliber/use-media-query'

import { resolveTileLink } from '/machinery/link'
import { useKeenSliderRefs } from '/machinery/useKeenSliderRefs'

import { ContainerLg } from '/features/buildingBlocks/Container'
import { Tile } from '/features/buildingBlocks/tile/Tile'

import 'keen-slider/keen-slider.min.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './TechblogSlider.css'

export function TechblogSlider({ slides, id = undefined, layoutClassName = undefined }) {
  const { ref: sliderRef } = useSlider({ slides })

  return (
    <div className={cx(styles.component, layoutClassName)} {...{ id }}>
      <ContainerLg>
        <div ref={sliderRef} className={cx(styles.slider, 'keen-slider')}>
          {slides.map((tile, index) => (
            <div key={index} className={cx(styles.slide, 'keen-slider__slide')}>
              <Tile
                representation={tile.representation}
                href={resolveTileLink({ tile, language: 'en' })}
              />
            </div>
          ))}
        </div>
      </ContainerLg>
    </div>
  )
}

function useSlider({ slides }) {
  const isViewportSm = useMediaQuery(mediaStyles.viewportSm)

  const { sliderRef } = useKeenSliderRefs({
    handleSlideChange: () => {},
    slidesNum: slides.length,
    slidesPerView: isViewportSm ? 'auto' : 1.2,
    spacing: 16,
    initialSlide: 0,
    sliderDisabled: false,
    origin: 0,
    loop: false,
    interactionTitle: 'procedure-slider',
  })

  return { ref: sliderRef }
}
