import { setOnVideoPreviewOpen } from '/features/pageOnly/wavemakers/videoPreviewHooks'
import { useFullscreenVideo } from '@kaliber/use-fullscreen-video'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'
import { reportClientError } from '/machinery/reportClientError'
import { Icon } from '/features/buildingBlocks/Icon'
import { animated, useSpring, useSpringValue } from 'react-spring'
import { useTranslate } from '/machinery/I18n'
import { ImageCover } from '/features/buildingBlocks/Image'
import { trackInteraction } from '/analytics/user-interaction'
import { getVideoMetaDataProps, trackVideoMetadata, useProgressTracker } from '/analytics/video'

import ReactPlayer from 'react-player/lazy'

import iconPlay from '/images/icons/play.raw.svg'

import styles from './VideoPreview.css'

const ReactPlayerBase = ReactPlayer
const previewHeight = parseFloat(styles.previewHeight)
const aspectRatio = Number(styles.aspectRatio)

export function VideoPreview({ preview, layoutClassName = undefined }) {
  const trackProgress = useProgressTracker({ title: 'video-preview' })
  const [animation, setState] = useSpringState()

  const isMounted = useRenderOnMount()
  const videoRef = React.useRef(null)

  setOnVideoPreviewOpen({ callback: x => setState(x) })

  const { refs, request } = useFullscreenVideo({
    options: { useVideoElement: true },
    onError: reportClientError,
    onChange: ({ state: { isFullscreen } }) => {
      const player = videoRef.current?.getInternalPlayer()

      isFullscreen
        ? typeof player?.play === 'function' && player.play()
        : typeof player?.pause === 'function' && player.pause()
    }
  })

  const { previewUrl, videoUrl, poster } = preview ?? {}
  const title = 'Video preview'

  return (
    <animated.div
      ref={refs.setContainer}
      className={cx(styles.component, layoutClassName)}
      style={animation}
    >
      {poster && (
        <Poster layoutClassName={styles.posterLayout} {...{ poster }} />
      )}

      {isMounted && (
        <Video
          url={videoUrl}
          onPlay={handlePlay}
          onPause={handlePause}
          onProgress={({ played }) => trackProgress(played)}
          layoutClassName={styles.videoLayout}
          {...{ refs, videoRef }}
        />
      )}

      {isMounted && (
        <Preview layoutClassName={styles.videoPreviewLayout} url={previewUrl} />
      )}

      <Text layoutClassName={styles.textLayout} />
      <Button layoutClassName={styles.buttonLayout} onFullscreenRequest={handleRequest} />
    </animated.div>
  )

  function handleRequest() {
    request()
    safeUnmuteOnAppleDevices()
    trackInteraction({
      category: 'preview-video',
      type: 'click',
      action: 'fullscreen requested'
    })
  }

  /**
   * Unmuting in iOS can only be via user gesture,
   * so it has to be called from handleRequest;
   * if you do not do this, it will NOT work.
   */
  function safeUnmuteOnAppleDevices() {
    const player = videoRef.current?.getInternalPlayer()
    if (player) player.muted = false
  }

  function handlePlay() {
    handleVideoTracking('video started')
  }

  function handlePause() {
    handleVideoTracking('video paused')
  }

  function handleVideoTracking(action) {
    const duration = videoRef.current?.getDuration()
    const currentTime = videoRef.current?.getCurrentTime()
    const progress = currentTime / duration * 100

    trackVideoMetadata({
      category: 'preview-video',
      type: 'play',
      action,
      video: getVideoMetaDataProps({ title, duration, progress })
    })
  }
}

function useSpringState() {
  const state = useSpringValue(/** @type {'expanded' | 'collapsed' | 'closed'} */ ('closed'))

  const size = previewHeight * aspectRatio
  const spring = useSpring({
    pointerEvents: onCloseState(x => x ? 'none' : 'auto'),
    gridTemplateColumns: onExpandedState(x => `${size}px ${x ? 20 : 0}ch` ),
    gap: onExpandedState(x => x ? 24 : 0),
    opacity: onCloseState(x => x ? 0 : 1),
    transform: onCloseState(x => x
      ? 'translateX(-5px) scale(0.95)'
      : 'translateX(0px) scale(1)'
    ),
  })


  return /** @type {const} */([spring, setSpring])

  function setSpring(x) {
    state.set(x)
  }

  function onCloseState(cb) {
    return state.to(x => cb(x === 'closed'))
  }

  function onExpandedState(cb) {
    return state.to(x => cb(x === 'expanded'))
  }
}

function Poster({ poster, layoutClassName }) {
  return (
    <div className={cx(styles.componentPoster, layoutClassName)}>
      <ImageCover
        image={poster}
        layoutClassName={styles.imageLayout}
        {...{ aspectRatio }}
      />
    </div>
  )
}

function Video({
  refs,
  url,
  videoRef,
  onPlay = undefined,
  onPause = undefined,
  onProgress = undefined,
  layoutClassName = undefined
}) {
  return (
    <ReactPlayerBase
      muted
      autoPlay
      playsinline
      width="100%"
      height="100%"
      ref={videoRef}
      onReady={handleReady}
      className={cx(styles.componentVideo, layoutClassName)}
      {...{ url, onPlay, onPause, onProgress }}
    />
  )

  function handleReady() {
    const videoPlayerElement = videoRef.current.getInternalPlayer()
    refs.setVideo(videoPlayerElement)
  }
}

function Preview({ url, layoutClassName }) {
  return (
    <ReactPlayerBase
      loop
      muted
      playing
      autoPlay
      playsinline
      width="100%"
      height="100%"
      className={cx(styles.componentPreview, layoutClassName)}
      {...{ url }}
    />
  )
}

function Text({ layoutClassName }) {
  const { __ } = useTranslate()

  return (
    <div className={cx(styles.componentText, layoutClassName)}>
      <p className={styles.textInner}>
        {__`wavemakers-view-preview-button`}
      </p>
    </div>
  )
}

function Button({ onFullscreenRequest, layoutClassName }) {
  return (
    <button onClick={onFullscreenRequest} className={cx(styles.componentButton, layoutClassName)}>
      <span className={styles.buttonInner}>
        <Icon icon={iconPlay} />
      </span>
    </button>
  )
}
